html {
  scroll-behavior: smooth;
  scroll-padding: 5rem;
}

.text-xxs {
  font-size: 0.875rem;
}

.mobile-menu {
  @apply flex lg:hidden flex-col w-full transition-all duration-500 absolute z-10 text-xl font-bold text-indigo-900 space-y-5 bg-white shadow-md px-14 pt-7 pb-16;
  top: -999px;

  &-open {
    @apply top-20;
  }
}

.desktop-menu {
  @apply hidden lg:flex transform-none static flex-row items-center shadow-none ml-auto w-auto h-full p-0 font-semibold text-indigo-900 text-base;
}

.sticky-menu {
  @apply shadow;
}

.input-error {
  @apply bg-pink-100 border border-pink-500 text-pink-500 rounded-md p-3 pt-5 transform -translate-y-2 z-10;
}

.hide-cookie {
  --tw-translate-x: 32rem;
}

button#mobile-menu-button {
  @apply rounded-full transition-all;
  &:hover,
  &:active {
    @apply transform rotate-90 transition-all;
  }
  &:focus {
    @apply ring-indigo-100 outline-none ring-2 transition-all transform rotate-90;
  }
}

#intro h1 {
  line-height: 3rem;
  @media (min-width: 768px) {
    line-height: 4.5rem;
  }
}

video:focus {
  outline: none;
}

.max-w-main-column {
  max-width: 90rem;
}

.custom-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

.quote-icon {
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

footer {
  @media (min-width: 1024px) {
    min-height: 20rem;
  }
}
